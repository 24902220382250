import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container } from "@mui/material";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";
import { DownloadPdf } from "../DownloadPdf";

import { StyledNewPatient } from "./NewPatient.styled";

import PdfHippa from "../../pdfs/hippa_form.pdf";
import PdfHandAuthorization from "../../pdfs/Joyner_Hand_Authorization.pdf";
import PdfPatientIntake from "../../pdfs/Joyner_Hand_Patient_Intake.pdf";
import PdfPhotoConsent from "../../pdfs/Joyner_Hand_Photo_Consent.pdf";
import PdfReferralForm from "../../pdfs/Joyner_Hand_Referral_Form.pdf";
import PdfWrittenAck from "../../pdfs/Joyner_Hand_Written_Acknowledgement.pdf";
import PdfMedicalHistory from "../../pdfs/Medical_History_TJH.pdf";

const NewPatientContent = ({ children }) => {
  return (
    <StyledNewPatient>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Save time, plan ahead
            </Typography>
            {/* <Typography variant="variant="h5"" align="center" mb={2}>
              <i>
                "Complete Hand Surgery - Care from Reconstruction to
                Rejuvenation"
              </i>
            </Typography> */}
            <Typography paragraph>
              Welcome to The Joyner Hand. Your first visit is very important. A
              little planning ahead can save you a lot of time. For starters,
              this checklist can give you a good idea of what to expect.
            </Typography>
            <Typography variant="h5">What to Bring</Typography>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  <li>Patient Medical Profile Form</li>
                  <li>Insurance Card</li>
                  <li>Emergency Room records</li>
                  <li>Medical Records/X-Rays</li>
                  <li>Electronic Medical Records</li>
                  <li>Written List of Prescriptions, medications, vitamins</li>
                  <li>A Family Member of Friend</li>
                  <li>Translator if needed</li>
                  <li>Questions</li>
                </ul>
              </Grid>
            </Grid>
            <Typography variant="h5">
              When You are at the Doctor's Office
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <b>Arrive at least 20 minutes early</b> to ensure that we
                    can take care of any last minute requirements before you see
                    the doctor.
                  </li>
                  <li>
                    <b>Ask Questions:</b> feel free to write down your
                    questions. If you don't understand a medical term or
                    recommendations let us know.
                  </li>
                </ul>
              </Grid>
            </Grid>

            <Typography variant="h5">When You Get Home</Typography>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  <li>
                    <b>Be sure to follow the "Doc's" instructions.</b> Take the
                    full course or medicine. Change dressings as instructed.
                    Remember, you're a part of the TJH Team too.
                  </li>
                  <li>
                    <b>Keep the "Doc" informed </b>of any changes in your
                    condition.
                  </li>
                  <li>
                    <b>Take a look at the website: </b>The Joyner Hand website
                    has information about many hand conditions and surgical
                    procedures. Feel free to view an animated version of your
                    scheduled procedure.
                  </li>
                </ul>
              </Grid>
            </Grid>

            <Typography variant="h5">
              Questions to ask at the visit or later, if your doctor recommends
              surgery
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  <li>Why is this procedure being recommended?</li>
                  <li>Are there alternatives?</li>
                  <li>What are the risks involved?</li>
                  <li>What is the procedure called?</li>
                  <li>How is it done? </li>
                  <li>What will happen if I don't have the surgery now? </li>
                  <li>If I want a second opinion, whom can I consult? </li>
                  <li>
                    Will I need any tests or medical evaluations prior to the
                    surgery?{" "}
                  </li>
                  <li>What kind of anesthesia will be used? </li>
                  <li>Will I meet with the anesthesiologist in advance? </li>
                  <li>How long will the recovery take? </li>
                  <li>What are my limitations during recovery? </li>
                  <li>
                    Will I need assistance at home afterwards? For how long?{" "}
                  </li>
                  <li>What will discharge instructions be? </li>
                  <li>Will I need physical therapy? </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="sm">
          <Box py={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Patient Forms</strong>
                </Typography>
                {/* <Box display="flex" justifyContent="center" mb={4}>
                  <StaticImage
                    src="../../images/spa.jpg"
                    alt="young old hand"
                  />
                </Box> */}
                <Box display="flex" justifyContent="center">
                  <Box>
                    <DownloadPdf title="Hippa Form" pdfSource={PdfHippa} />
                    <DownloadPdf
                      title="Hand Authorization"
                      pdfSource={PdfHandAuthorization}
                    />
                    <DownloadPdf
                      title="Patient Intake"
                      pdfSource={PdfPatientIntake}
                    />
                    <DownloadPdf
                      title="Photo Consent"
                      pdfSource={PdfPhotoConsent}
                    />
                    <DownloadPdf
                      title="Referral Form"
                      pdfSource={PdfReferralForm}
                    />
                    <DownloadPdf
                      title="Written Acknowledgement"
                      pdfSource={PdfWrittenAck}
                    />
                    <DownloadPdf
                      title="Medical History"
                      pdfSource={PdfMedicalHistory}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledNewPatient>
  );
};

const NewPatient = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero-new-patient.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="secondary">
              <strong>New Patient</strong>
            </Typography>
            {/* <Typography variant="h4" align="center" color="secondary">
              Your hands deserve it
            </Typography> */}
          </Box>
        </Box>
      </BgImage>
      <NewPatientContent />
    </>
  );
};

export default NewPatient;
