import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StyledDownloadPdf = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  display: "block",
  color: "white",
}));

export { StyledDownloadPdf };
