import * as React from "react";
import { Layout } from "../components/Layout";
import { NewPatient } from "../components/NewPatient";

export default function NewPatientPage() {
  return (
    <Layout>
      <NewPatient />
    </Layout>
  );
}
