
  import { styled } from '@mui/system';
  import { Box } from '@mui/material';

  const StyledNewPatient = styled(Box)(({theme})=>({
    padding: theme.spacing(0)
  }));

  export { StyledNewPatient };
  
      
      