import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { StyledDownloadPdf } from "./DownloadPdf.styled";

const DownloadPdf = ({ title, pdfSource }) => {
  return (
    <StyledDownloadPdf href={pdfSource} component="a" download>
      <PictureAsPdfIcon />
      <Box sx={{ display: "inline-block", margin: "2px 2px 4px 4px" }}>
        {title}
      </Box>
    </StyledDownloadPdf>
  );
};

DownloadPdf.propTypes = {
  title: PropTypes.string,
  pdfSource: PropTypes.node,
};

export default DownloadPdf;
